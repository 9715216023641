import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useParams,
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import SweetAlert2 from "react-sweetalert2";
let BASEURL;
if (window.location.hostname === "localhost") {
  BASEURL = "http://localhost:2020/api/v1";
} else {
  BASEURL = "https://clinicapi.ideaclinics.com/api/v1";
}
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/:review_id" Component={Home} />
          <Route path="/thankyou" Component={ThankyouPage} />
        </Routes>
      </Router>
    </>
  );
}

const Home = () => {
  const [swalProps, setSwalProps] = useState({});

  const [rating, setRating] = useState(0);
  const [data, setData] = useState(null);

  const navigate = useNavigate();
  const { review_id } = useParams(); // Capture the encrypted ID from the URL

  useEffect(() => {
    // Check if review_id has changed
    console.log(review_id);
    if (review_id) {
      fetch(`${BASEURL}/review/info`, {
        body: JSON.stringify({
          review_id: review_id,
        }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((x) => x.json())
        .then((res) => {
          if (res.score > 0) {
            navigate("/thankyou", { replace: true });
          } else {
            setData(res);
          }
        })
        .catch((e) => {
          setSwalProps({
            show: true,
            title: "Error",
            text: "Something went wrong",
          });
        });
    }
  }, [review_id]); // Only run the effect when review_id changes

  const updateFeedback = (obj) => {
    return new Promise((res, rej) => {
      if (review_id) {
        fetch(`${BASEURL}/review/update`, {
          body: JSON.stringify(obj),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((x) => x.text())
          .then((r) => res(r))
          .catch((e) => rej(e));
      }
    });
  };

  return (
    <>
      <SweetAlert2 {...swalProps} />

      <div className="feedback-container">
        <div className="border-header"></div>
        <img src="assets/svg/feedback_logo.svg" className="logo_img" />
        <p className="feedback-title">
          <span>Your Feedback is really important to us!</span>
          <br />
        </p>
        <div className="star-rating">
          {[5, 4, 3, 2, 1].map((star) => (
            <React.Fragment key={star}>
              <input
                id={`star-${star}`}
                type="radio"
                name="rating"
                value={star}
                checked={rating === star}
                onChange={(e) => {
                  updateFeedback({
                    review_id: review_id,
                    score: e.target.value,
                  }).then((x) => {
                    setRating(e.target.value);
                    if (e.target.value <= 4) {
                      window.location.href =
                        "https://www.ideaclinics.com/feedback/";
                    } else {
                      window.location.href = data?.clinic_link;
                    }
                  });
                }}
              />
              <label
                className="label"
                htmlFor={`star-${star}`}
                title={`${star} stars`}
              >
                <i className="active fa fa-star" aria-hidden="true" />
              </label>
            </React.Fragment>
          ))}
        </div>

        <p
          style={{
            color: "#000000",
            direction: "ltr",
            marginLeft: "0em",
            lineHeight: "0.92306805em",
            textAlign: "center",
            marginTop: "1rem",
          }}
        >
          <span style={{ color: "#000000" }}>
            This link is for one-time use only
          </span>
        </p>
      </div>
    </>
  );
};

const ThankyouPage = () => {
  return (
    <>
      <>
        <div className="content">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <h1>Thank you !</h1>
              <p>Thanks for your Feedback.</p>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default App;
